<template>
  <div class="income">
    <van-nav-bar title="收益详情" left-arrow @click-left="onClickLeft" />
    <van-notice-bar left-icon="volume-o" text="收益会随着订单取消或售后产生的退款而变化，仅供参考。" />
    <div class="main">
      <van-tabs v-model="incomeActive" swipe-threshold="5" @click="incomeTabs">
        <van-tab title="所有">
          <div v-if="list.length > 0 ? true: false">
            <van-list
              :immediate-check="false"
              v-model="currLoading[incomeActive]"
              :finished="currFinished[incomeActive]"
              finished-text="没有更多了"
              @load="onIncomeLoad()"
            >
              <div class="list_main van-hairline--top" v-for="(item, index) in list" :key="index">
                <van-cell-group :border="false">
                  <van-cell :border="false">
                    <template #icon>
                      <div style="margin-right: 5px;">
                        {{item.applyno}}
                      </div>
                    </template>
                    <template #title>
                      <div>
                        <van-button size="mini" plain @click="copy(item.applyno)">复制</van-button>
                      </div>
                    </template>
                    <template #default>
                      +{{item.realmoney}}
                    </template>
                  </van-cell>
                  <van-cell :border="false" :title="item.dealtime" @click="showAddRealname(item.id, item.realname, item.response)">
                    <template #default>
                      <span :class="item.response ? 'red' : ''">{{item.response ? item.response : item.statusstr}}</span>
                    </template>
                  </van-cell>
                </van-cell-group>
                <div class="van-hairline--top seedetails">
                  <van-cell @click="seeDetail(item.id)" :border="false" value="查看详情" is-link>
                    <template #title>
                      <div>
                        <van-tag plain v-if="item.status == '1'" type="primary">待审核</van-tag>
                        <van-tag plain v-if="item.status == '2'" type="danger">待打款</van-tag>
                        <van-tag plain v-if="item.status == '3'" type="success">已打款</van-tag>
                        <van-tag plain v-if="item.status == '-1'" type="warning">无效</van-tag>
                        提现到微信钱包
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="待审核">
          <div v-if="list.length > 0 ? true: false">
            <van-list
              :immediate-check="false"
              v-model="currLoading[incomeActive]"
              :finished="currFinished[incomeActive]"
              finished-text="没有更多了"
              @load="onIncomeLoad()"
            >
              <div class="list_main van-hairline--top" v-for="(item, index) in list" :key="index">
                <van-cell-group :border="false">
                  <van-cell :border="false">
                    <template #icon>
                      <div style="margin-right: 5px;">
                        {{item.applyno}}
                      </div>
                    </template>
                    <template #title>
                      <div>
                        <van-button size="mini" plain @click="copy(item.applyno)">复制</van-button>
                      </div>
                    </template>
                    <template #default>
                      +{{item.realmoney}}
                    </template>
                  </van-cell>
                  <van-cell :border="false" :title="item.dealtime" @click="showAddRealname(item.id, item.realname, item.response)">
                    <template #default>
                      <span :class="item.response ? 'red' : ''">{{item.response ? item.response : item.statusstr}}</span>
                    </template>
                  </van-cell>
                </van-cell-group>
                <div class="van-hairline--top seedetails">
                  <van-cell @click="seeDetail(item.id)" :border="false" value="查看详情" is-link>
                    <template #title>
                      <div>
                        <van-tag plain type="primary">待审核</van-tag>
                        提现到微信钱包
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="待打款">
          <div v-if="list.length > 0 ? true: false">
            <van-list
              :immediate-check="false"
              v-model="currLoading[incomeActive]"
              :finished="currFinished[incomeActive]"
              finished-text="没有更多了"
              @load="onIncomeLoad()"
            >
              <div class="list_main van-hairline--top" v-for="(item, index) in list" :key="index">
                <van-cell-group :border="false">
                  <van-cell :border="false">
                    <template #icon>
                      <div style="margin-right: 5px;">
                        {{item.applyno}}
                      </div>
                    </template>
                    <template #title>
                      <div>
                        <van-button size="mini" plain @click="copy(item.applyno)">复制</van-button>
                      </div>
                    </template>
                    <template #default>
                      +{{item.realmoney}}
                    </template>
                  </van-cell>
                  <van-cell :border="false" :title="item.dealtime" @click="showAddRealname(item.id, item.realname, item.response)">
                    <template #default>
                      <span :class="item.response ? 'red' : ''">{{item.response ? item.response : item.statusstr}}</span>
                    </template>
                  </van-cell>
                </van-cell-group>
                <div class="van-hairline--top seedetails">
                  <van-cell @click="seeDetail(item.id)" :border="false" value="查看详情" is-link>
                    <template #title>
                      <div>
                        <van-tag plain type="danger">待打款</van-tag>
                        提现到微信钱包
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="已打款">
          <div v-if="list.length > 0 ? true: false">
            <van-list
              :immediate-check="false"
              v-model="currLoading[incomeActive]"
              :finished="currFinished[incomeActive]"
              finished-text="没有更多了"
              @load="onIncomeLoad()"
            >
              <div class="list_main van-hairline--top" v-for="(item, index) in list" :key="index">
                <van-cell-group :border="false">
                  <van-cell :border="false">
                    <template #icon>
                      <div style="margin-right: 5px;">
                        {{item.applyno}}
                      </div>
                    </template>
                    <template #title>
                      <div>
                        <van-button size="mini" plain @click="copy(item.applyno)">复制</van-button>
                      </div>
                    </template>
                    <template #default>
                      +{{item.realmoney}}
                    </template>
                  </van-cell>
                  <van-cell :border="false" :title="item.dealtime" @click="showAddRealname(item.id, item.realname, item.response)">
                    <template #default>
                      <span :class="item.response ? 'red' : ''">{{item.response ? item.response : item.statusstr}}</span>
                    </template>
                  </van-cell>
                </van-cell-group>
                <div class="van-hairline--top seedetails">
                  <van-cell @click="seeDetail(item.id)" :border="false" value="查看详情" is-link>
                    <template #title>
                      <div>
                        <van-tag plain type="success">已打款</van-tag>
                        提现到微信钱包
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
        <van-tab title="无效">
          <div v-if="list.length > 0 ? true: false">
            <van-list
              :immediate-check="false"
              v-model="currLoading[incomeActive]"
              :finished="currFinished[incomeActive]"
              finished-text="没有更多了"
              @load="onIncomeLoad()"
            >
              <div class="list_main van-hairline--top" v-for="(item, index) in list" :key="index">
                <van-cell-group :border="false">
                  <van-cell :border="false">
                    <template #icon>
                      <div style="margin-right: 5px;">
                        {{item.applyno}}
                      </div>
                    </template>
                    <template #title>
                      <div>
                        <van-button size="mini" plain @click="copy(item.applyno)">复制</van-button>
                      </div>
                    </template>
                    <template #default>
                      +{{item.realmoney}}
                    </template>
                  </van-cell>
                  <van-cell :border="false" :title="item.dealtime" @click="showAddRealname(item.id, item.realname, item.response)">
                    <template #default>
                      <span :class="item.response ? 'red' : ''">{{item.response ? item.response : item.statusstr}}</span>
                    </template>
                  </van-cell>
                </van-cell-group>
                <div class="van-hairline--top seedetails">
                  <van-cell @click="seeDetail(item.id)" :border="false" value="查看详情" is-link>
                    <template #title>
                      <div>
                        <van-tag plain type="warning">无效</van-tag>
                        提现到微信钱包
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="main" v-if="nodata">
      <van-empty description="暂无数据" />
    </div>
    <!-- 加载中提示 -->
    <div id="loading" v-show="loadshow">
      <van-loading size="24px" type="spinner" vertical>加载中...</van-loading>
    </div>
        
    <!-- 修改真是姓名弹框 -->
    <van-dialog
      title="修改真实姓名"
      v-model="showAddName"
      show-cancel-button
      @confirm="onsubmit"
    >
      <div style="margin: 0 16px;overflow: hidden;" class="dialog_div">
        <van-field
          v-model="realname"
          placeholder="请输入真实姓名"
          :border="false"
          input-align="center"
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
export default {
  name: "Income",
  filters: {
    currencyFormat: function(num) {
      if (num === 0) {
        return "0.00";
      } else {
        num = num + "";
        if (num.length == 1) {
          num = "00" + num;
        }
        if (num.length == 2) {
          num = "0" + num;
        }
        let start = 0;
        start = num.length - 2;
        return num.slice(0, start) + "." + num.slice(start);
      }
    },
    dateFormat: function(value) {
      let date = null;
      let y = null;
      date = new Date(value);
      y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      /*
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      */
      return y + "-" + MM + "-" + d + ' ' + h + ':' + m; // + ':' + s;
    }
  },
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      isLogin: localStorage.getItem("DK_ISLOGIN")
        ? localStorage.getItem("DK_ISLOGIN")
        : false,
      userinfo: {},
      incomeActive: 0, // 默认在哪里
      list: [],
      currSize: 20,
      currOffset: [0, 0, 0, 0, 0],
      income: [[], [], [], [], []], // 列表
      currLoading: [false, false, false, false, false],
      currFinished: [false, false, false, false, false],
      startDate: new Date().setDate(1), // 开始日期
      endDate: new Date(), // 结束日期
      dateShow: false,
      dateShowType: 1, // 1 开始时间，2 结束时间
      shareid: 0,
      merchid: 0,
      nodata: false,
      loadshow: false,
      logid: 0,
      showAddName: false, 
      realname: '',
    };
  },
  mounted() {
    this.incomeActive = this.$route.query.status;
    this.init();
    
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;

    this.$parent.getmerchname(this.merchid, "收益详情");
  },
  methods: {
    init() {
      this.getIncome(); // 获取数据
    },
    // 获取数据
    getIncome() {
      let _that = null;
      _that = this;
      this.loadshow = true
      _that.$axios
        .post(
          _that.$store.state.domain + "web/commission/log",
          _that.$qs.stringify({
            status: _that.incomeActive,
            offset: _that.currOffset[_that.incomeActive],
            size: _that.currSize,
          })
        )
        .then(response => {
          console.log(response);
          _that.nodata = false;
          _that.loadshow = false;
          if (response.data.code == 100000) {
            if (response.data.data.length <= _that.currSize) {
              _that.currFinished[_that.incomeActive] = true; // 加载完毕
            }
            _that.list = response.data.data;
            _that.income[_that.incomeActive] = response.data.data;
          }else{
            _that.currFinished[_that.incomeActive] = true; // 加载完毕
            _that.nodata = true;
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 返回上一页
    onClickLeft() {
      this.$router.go(-1);
    },
    // 加载更多
    onIncomeLoad() {
      let _that = null;
      _that = this;

      _that.$axios
        .post(
          _that.$store.state.domain + "web/commission/log",
          _that.$qs.stringify({
            status: _that.incomeActive,
            offset: _that.currOffset[_that.incomeActive],
            size: _that.currSize,
          })
        )
        .then(response => {
          if (response.data.code == 100000) {
            if (response.data.data.length <= _that.currSize) {
              _that.currFinished[_that.incomeActive] = true; // 加载完毕
            }
            response.data.data.forEach(item => {
              if (parseInt(item.status) == -2 || parseInt(item.status) == -1) {
                item.color = "red"
              } else if (parseInt(item.status) == 3) {
                item.color = "green"
              } else if (parseInt(item.status) == 0 || parseInt(item.status) == 1 || parseInt(item.status) == 2) {
                item.color = "gray"
              }
              _that.list.push(item);
              _that.income[_that.incomeActive].push(item);
            })
          }else{
            _that.currFinished[_that.incomeActive] = true; // 加载完毕
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    //切换标签
    incomeTabs(name) {
      console.log(name);
      this.incomeActive = name;
      this.currOffset[name] = 0;
      this.list = [];
      this.currFinished[this.incomeActive] = false;
      this.getIncome();
      this.$router.replace({ name: "Income", query: {status: name, shareid: this.shareid, merchid: this.merchid} });
    },
    // 查看详情
    seeDetail(id) {
      this.$router.push({ name: "SeeDetail", query: { id: id, shareid: this.shareid, merchid: this.merchid } });
    },
    // 复制提现单号
    copy(sn) {
      let _that = null;
        _that = this;
        _that.$copyText(sn).then(
          function(e) {
            _that.$toast("复制成功");
            console.log(e);
          },
          function(e) {
            console.log(e);
          }
        );
    },
    showAddRealname(id, name, response = '') {
      this.logid = id;
      this.realname = name;

      this.showAddName = false;
      if (response && response.length > 0) {
        this.showAddName = true;
      }
    },
    // 修改真实姓名
    onsubmit() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/commission/update_withdraw_realname",
          _that.$qs.stringify({
            "log_id": _that.logid,
            realname: _that.realname
          })
        )
        .then(res => {
          if(res.data.code == 100000) {
            _that.$toast("修改成功");
          }else {
            _that.$toast({
              type: "text",
              message: res.data.msg ? res.data.msg : "修改失败"
            })
          }
        })
    },
  }
};
</script>
<style lang="less">
.income {
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 1;
  background-color: #ededed;
  .van-nav-bar {
    .van-sticky .van-tabs__wrap .van-tab{
      background-color: #fff;
      overflow: inherit;
    }
    .van-icon {
      color: #333;
    }
  }
  .main {
    .list_main {
      padding: 16px;
      background-color: #fff;
      font-size: 14px;
      color: #333;
      margin: 10px;
      border-radius: 4px;
      overflow: hidden;
      .van-cell{
        padding: 0;
        .van-cell__title,
        .van-cell__value {
          flex: 1;
          color: #333;
        }
        .van-cell__title{
          text-align: left;
        }
      }
      .seedetails{
        margin-top: 10px;
        padding-top: 10px;
        .van-cell {
          padding: 0;
        }
      }
      .van-hairline--top::after{
        border-color: #eee;
        border-top-width: 2px;
      }
      .red { // -2, -1
        color: #EE0A24;
      }
      .green { // 3
        color: #07C160;
      }
      .gray { // 0 1 2
        color: #999;
      }
    }
  }
  // 加载中
  #loading {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      padding: 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
    }
  }
  .dialog_div {
    .van-cell__value {
      flex: 1;
    }
  }
}
</style>